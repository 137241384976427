import { graphql, useStaticQuery } from 'gatsby';

import Layout from 'layouts/default';

import HighlightCards from 'components/content-blocks/highlight';
import Yoast from 'components/globals/yoast';
import { HeroNotZoomEvents } from 'components/hero/not-zoom-event';
import Link from 'components/link/link';

import '../../templates/styles/events.scss';
/*
This is a basic page template. 
In order for this template to work, don't create a file in /page that controls a page in wordpress.
If pages are created in gatsby then it will stop listening to this page template all together.
The following pages use the template:
- events
*/

const EventsShiftBasedWorkplace = () => {
  const data = useStaticQuery(graphql`
    {
      allWordpressPage(
        filter: {
          slug: { eq: "how-shift-based-workplaces-will-succeed-post-pandemic" }
        }
      ) {
        edges {
          node {
            slug
            wordpress_id
        yoast_head_json {
          title
          description
          canonical
          og_title
          og_description
          og_image {
            url {
              source_url
            }
          }
          og_url
          og_type
          twitter_title
          twitter_description
          twitter_image {
            source_url
          }
        }
            acf {
              not_zoom_webinar_hero {
                title
                copy
                webinar_video {
                  title
                  copy
                  image {
                    source_url
                  }
                  video_id
                  button_text
                }
                background_image {
                  source_url
                }
              }
              cards_1 {
                card {
                  copy
                  icon
                  title
                }
              }
              leader {
                title
                leader {
                  head_shot {
                    source_url
                  }
                  name
                  title
                  copy
                  link
                }
              }
            }
          }
        }
      }
    }
  `);

  const pageContext = data.allWordpressPage.edges[0].node.acf;
  const yoast = data.allWordpressPage.edges[0].node.yoast_head_json;
  const hero = pageContext.not_zoom_webinar_hero;
  const cards_1 = pageContext.cards_1;
  const presenters = pageContext.leader;

  return (
    <Layout>
      <Yoast { ...yoast } />
      <div className="events-template-container events-shift-based-workplace-page-container" data-signal={ data.allWordpressPage.edges[0].node.wordpress_id }>
        <HeroNotZoomEvents { ...hero } />
        <div className="components-wrapper no-customer-quotes">
          <HighlightCards
            { ...cards_1 }
            hasCopy
            hasTitle
            hasShadow
            hasFontIcon
          />
          <section className="section has-text-centered leadership">
            <div className="container">
              <h2 className="title">{presenters.title}</h2>
              <div className="leadership-team columns">
                {presenters.leader.map((person, index) => (
                  <div key={ index } className="column is-half">
                    <Link
                      className="leadership-linkedin-link"
                      to={ person.link }
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <figure className="leadership-image">
                        <img
                          loading="lazy"
                          src={ person.head_shot?.source_url }
                          alt={ person.name }
                        />
                      </figure>
                    </Link>
                    <h3>
                      <strong>{person.name}</strong>
                    </h3>
                    <h4>{person.title}</h4>
                    <p>{person.copy}</p>
                  </div>
                ))}
              </div>
            </div>
          </section>
        </div>
      </div>
    </Layout>
  );
};

export default EventsShiftBasedWorkplace;
