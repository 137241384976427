import { Fragment, useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import queryString from 'query-string';

import windowCheck from 'utils/helpers/window-check';

import WebinarIllustration from 'components/content-blocks/webinar-registration/partials/webinar-illustration';
import { setRegisteredInLocalStorage } from 'components/content-blocks/webinar-registration/webinar-local-storage';
import VeroWebinarSignUpForm from 'components/forms/vero-webinar';
import VideoModal from 'components/modal/video';

import './styles/events.scss';
import './styles/not-zoom-event.scss';

type HeroNotZoomEventsProps = {
  background_image: {
      source_url: string
  },
  copy: string
  title: string,
  webinar_video: {
      button_text,
      copy: string
      image: {
          source_url: string
      }
      title: string,
      video_id: string
  }
}

export const HeroNotZoomEvents = ({ 
  title,
  copy,
  webinar_video,
  background_image,

}:HeroNotZoomEventsProps) => {
// show replay query string
  const [hasRegistered, setHasRegistered] = useState(() => {
    if (windowCheck && queryString.parse(location.search).show_replay) return true;
    const registrationsFromLocalStorage = windowCheck && window.localStorage?.getItem('wiw-zoom-webinar-registrations');
    return Boolean(registrationsFromLocalStorage);
  });

  const onRegistrationSuccess = () => {
    setHasRegistered(true);
    setRegisteredInLocalStorage(8008);
  };

  const breakpoints = useBreakpoint();

  return (
    <section
      className="section hero events-hero-section not-zoom-webinar-hero-section"
      data-section="heroZoom"
    >
      <div className="hero-main container">
        <div className="columns">
          <div className="column">
            <div className="hero-text">
              <h1 className="title">{title}</h1>
              <p className="copy">{copy}</p>
            </div>
          </div>
          <div className="column">
            <div className="event-container">
              {!hasRegistered ? (
                <Fragment>
                  <p className="event-registration--headline">{webinar_video.title}</p>
                  <div dangerouslySetInnerHTML={ { __html: webinar_video.copy } } />
                  <VeroWebinarSignUpForm
                    onRegistration={ onRegistrationSuccess }
                    webinarTitle={ webinar_video.title }
                    submitButtonText="Register for Webinar"
                  />
                </Fragment>
                   
              ) : (
                <Fragment>
                  <p className="event-registration--headline">Thanks for registering!</p>
                  <p>Here's the recording of our webinar from<br /><strong>Wednesday, July 27, 2021.</strong></p>
                  <WebinarIllustration />
                  <div className="registration-row">
                    <VideoModal
                      className="cta-button-global full-width"
                      hashedId={ webinar_video.video_id }
                      text="Watch it Now"
                    />
                  </div>

                </Fragment>)}
            </div>
          </div>
        </div>
        {!breakpoints.sm && (
          <img
            aria-hidden="true"
            className="hero-background-image"
            src={ background_image.source_url }
            alt="Illustration of a diverse community"
          />
        )}
      </div>
    </section>
  );
};