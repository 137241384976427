import { windowCheck } from 'utils/helpers';

const itemKey = 'wiw-zoom-webinar-registrations';

type RegistrationLSEntry = {
  zoomWebinarId: number
}

export function setRegisteredInLocalStorage(zoomWebinarId: number) {
  if (!windowCheck)
    return;

  const lsValue = getRawLocalStorageValue();
  if (lsValue) {
    const parsedValue = JSON.parse(lsValue) as RegistrationLSEntry[];
    parsedValue.push({ zoomWebinarId });

  } else {
    writeToLocalStorage([{ zoomWebinarId }]);
  }
}

export function getRegistrationsFromLocalStorage(): number[] {
  if (!windowCheck)
    return [];

  const lsValue = getRawLocalStorageValue();

  if (!lsValue)
    return [];

  const parsedValue = JSON.parse(lsValue) as RegistrationLSEntry[];

  return parsedValue.map(value => value.zoomWebinarId);
}

function getRawLocalStorageValue(): string | null {
  return window.localStorage.getItem(itemKey);
}

function writeToLocalStorage(value: RegistrationLSEntry[]) {
  window.localStorage.setItem(itemKey, JSON.stringify(value));
}
